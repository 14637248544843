import Head from 'next/head';

interface CustomHeadProps {
  favicon: string;
  isIconsWithResolutions: boolean;
}

const CustomHead = ({ favicon, isIconsWithResolutions }: CustomHeadProps) => {
  return (
    <Head>
      {isIconsWithResolutions && (
        <>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1"
            key="viewport"
          />
          <link
            rel="apple-touch-icon"
            sizes="32x32"
            href="/apple-touch-icon.png"
            key="apple"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
            key="icon32"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
            key="icon16"
          />
        </>
      )}
      <link rel="icon" href={favicon} key="favicon" />
    </Head>
  );
};

export default CustomHead;
