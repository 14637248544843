import { useCallback } from 'react';

import { GaEventData } from '../types/Ga';

type LogToGa = (eventData: GaEventData) => void;

const useGa = (): LogToGa => {
  return useCallback((eventData: GaEventData) => {
    try {
      const gaEvent: GaEventData = {
        ...eventData,
        _clear: true,
      };

      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(gaEvent);
      });
    } catch (error) {
      console.error('Unable to log Ga event', eventData, error);
    }
  }, []);
};

export default useGa;
