import { MouseEvent } from 'react';

import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormState } from 'react-hook-form';

import useTimetablesStore from '@calendar/store/useTimetablesStore';
import { CreateEvent } from '@event/types';
import usePopupStore from '@popup/stores/usePopupStore';
import ConditionalWrapper from '@ui/components/ConditionalWrapper';
import { isCreateEventPath, isHomepagePath } from '@utils/routeHelpers';

export interface LogoProps {
  className?: string;
  withGradient?: boolean;
  formState?: FormState<CreateEvent>;
}
const Logo = ({ className, withGradient = true, formState }: LogoProps) => {
  const { pathname } = useRouter();
  const { timetables } = useTimetablesStore();

  const { setPopupState } = usePopupStore();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (
      (timetables.length > 0 || formState?.isDirty) &&
      isCreateEventPath(pathname)
    ) {
      event.preventDefault();
      setPopupState({
        isLeavePromptModalOpen: true,
      });
    }
  };

  return (
    <div className="flex">
      <ConditionalWrapper
        condition={isHomepagePath(pathname)}
        wrapper={(wrapperChildren) => <h1>{wrapperChildren}</h1>}
      >
        <ConditionalWrapper
          condition={!isHomepagePath(pathname)}
          wrapper={(wrapperChildren) => (
            <Link href="/" onClick={handleClick} className="flex">
              {wrapperChildren}
            </Link>
          )}
        >
          <svg
            width="96"
            height="45"
            viewBox="0 0 96 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(
              'w-full min-w-[68px] max-w-[96px] transition-all duration-300 motion-reduce:transition-none',
              className,
            )}
            style={{
              filter: !isHomepagePath(pathname)
                ? 'drop-shadow(0px 4px 10px rgba(143, 143, 143, 0.5)'
                : 'none',
            }}
          >
            <path
              d="M3.41624 44.0726C2.42733 44.0726 1.60324 43.8479 0.943962 43.3983C0.314654 42.9488 0 42.4544 0 41.915V15.0345C0 14.4052 0.314654 13.8958 0.943962 13.5062C1.60324 13.1167 2.39736 12.9219 3.32634 12.9219C4.25532 12.9219 5.01948 13.1167 5.61882 13.5062C6.21816 13.8958 6.51783 14.4052 6.51783 15.0345V16.0684C7.02727 15.1994 7.80641 14.4202 8.85526 13.731C9.93407 13.0118 11.1777 12.6522 12.5862 12.6522C14.1145 12.6522 15.478 13.0867 16.6767 13.9557C17.9053 14.7948 18.8643 15.9336 19.5535 17.372C20.2727 18.7804 20.6323 20.3537 20.6323 22.0918V25.2383C20.6323 26.8865 20.2727 28.4448 19.5535 29.9132C18.8343 31.3516 17.8753 32.5203 16.6767 33.4193C15.5079 34.3183 14.2194 34.7678 12.8109 34.7678C11.4924 34.7678 10.2937 34.4082 9.21487 33.689C8.13605 32.9398 7.38688 32.1008 6.96734 31.1718V41.915C6.96734 42.4544 6.62272 42.9488 5.93347 43.3983C5.24423 43.8479 4.40516 44.0726 3.41624 44.0726ZM10.3386 28.6995C10.938 28.6995 11.4774 28.5347 11.9568 28.2051C12.4663 27.8754 12.8708 27.4409 13.1705 26.9015C13.5002 26.3621 13.665 25.8077 13.665 25.2383V22.0918C13.665 21.5224 13.5151 20.983 13.2155 20.4736C12.9158 19.9641 12.4963 19.5446 11.9568 19.2149C11.4474 18.8853 10.878 18.7205 10.2487 18.7205C9.70932 18.7205 9.1849 18.8553 8.67546 19.125C8.19599 19.3947 7.79143 19.7843 7.46179 20.2938C7.13216 20.7732 6.96734 21.3726 6.96734 22.0918V25.8227C6.96734 26.0624 7.10219 26.422 7.37189 26.9015C7.67156 27.351 8.07612 27.7705 8.58556 28.1601C9.095 28.5197 9.67935 28.6995 10.3386 28.6995Z"
              fill={clsx(withGradient && 'url(#paint0_linear_473_1486)')}
            />
            <path
              d="M32.1488 34.7678C30.2908 34.7678 28.5827 34.3333 27.0244 33.4643C25.4661 32.5653 24.2225 31.3965 23.2935 29.9581C22.3645 28.5197 21.9001 26.9764 21.9001 25.3282V15.0795C21.9001 14.5401 22.2447 14.0456 22.9339 13.5961C23.6232 13.1466 24.4473 12.9219 25.4062 12.9219C26.3352 12.9219 27.1443 13.1466 27.8335 13.5961C28.5228 14.0456 28.8674 14.5401 28.8674 15.0795V25.3282C28.8674 25.8976 29.0172 26.437 29.3169 26.9464C29.6166 27.4559 30.0061 27.8754 30.4856 28.2051C30.9951 28.5347 31.5494 28.6995 32.1488 28.6995C32.7481 28.6995 33.2875 28.5347 33.767 28.2051C34.2764 27.8754 34.666 27.4559 34.9357 26.9464C35.2354 26.437 35.3852 25.8976 35.3852 25.3282V15.0345C35.3852 14.4652 35.7298 13.9707 36.4191 13.5512C37.1383 13.1316 37.9624 12.9219 38.8914 12.9219C39.9102 12.9219 40.7343 13.1316 41.3636 13.5512C42.0229 13.9707 42.3526 14.4652 42.3526 15.0345V25.3282C42.3526 27.0064 41.8881 28.5647 40.9591 30.0031C40.0301 31.4415 38.7865 32.5952 37.2282 33.4643C35.6999 34.3333 34.0067 34.7678 32.1488 34.7678Z"
              fill={clsx(withGradient && 'url(#paint1_linear_473_1486)')}
            />
            <path
              d="M44.2026 44.0726C42.7342 44.0726 41.7602 43.7729 41.2808 43.1736C40.8013 42.5743 40.5616 41.93 40.5616 41.2407C40.5616 40.3117 40.8163 39.5775 41.3257 39.0381C41.8352 38.4987 42.4495 38.229 43.1687 38.229C43.5283 38.229 43.798 38.1092 43.9778 37.8694C44.1576 37.6597 44.2625 37.345 44.2925 36.9255C44.3524 36.5359 44.3824 36.0864 44.3824 35.5769V15.0345C44.3824 14.4052 44.697 13.8958 45.3263 13.5062C45.9856 13.1167 46.8097 12.9219 47.7986 12.9219C48.7875 12.9219 49.6266 13.1167 50.3158 13.5062C51.0051 13.8958 51.3497 14.4052 51.3497 15.0345V35.5769C51.3497 37.2251 51.065 38.6935 50.4956 39.9821C49.9562 41.2707 49.1471 42.2746 48.0683 42.9938C47.0195 43.713 45.7309 44.0726 44.2026 44.0726Z"
              fill={clsx(withGradient && 'url(#paint2_linear_473_1486)')}
            />
            <path
              d="M60.6597 34.7678C59.2812 34.7678 57.9927 34.3183 56.794 33.4193C55.5953 32.5203 54.6364 31.3516 53.9171 29.9132C53.1979 28.4448 52.8383 26.8865 52.8383 25.2383V22.0918C52.8383 20.3537 53.1979 18.7804 53.9171 17.372C54.6364 15.9336 55.5953 14.7948 56.794 13.9557C57.9927 13.0867 59.3262 12.6522 60.7946 12.6522C62.1731 12.6522 63.3418 13.0118 64.3007 13.731C65.2896 14.4202 66.0238 15.1994 66.5033 16.0684V2.80799C66.5033 2.17868 66.8479 1.66924 67.5372 1.27967C68.2264 0.890098 69.0655 0.695312 70.0544 0.695312C71.0433 0.695312 71.8524 0.890098 72.4817 1.27967C73.141 1.66924 73.4706 2.17868 73.4706 2.80799V32.1607C73.4706 32.7001 73.141 33.1946 72.4817 33.6441C71.8524 34.0936 71.0433 34.3183 70.0544 34.3183C69.2153 34.3183 68.4811 34.0936 67.8518 33.6441C67.2525 33.1946 66.9528 32.7001 66.9528 32.1607V31.1718C66.5033 32.1008 65.6942 32.9398 64.5255 33.689C63.3867 34.4082 62.0981 34.7678 60.6597 34.7678ZM63.0421 28.6995C63.7014 28.6995 64.2857 28.5197 64.7952 28.1601C65.3046 27.7705 65.7092 27.351 66.0088 26.9015C66.3385 26.422 66.5033 26.0624 66.5033 25.8227V22.0918C66.5033 21.3726 66.3235 20.7732 65.9639 20.2938C65.6343 19.7843 65.1997 19.3947 64.6603 19.125C64.1509 18.8553 63.6265 18.7205 63.0871 18.7205C62.4877 18.7205 61.9333 18.8853 61.4239 19.2149C60.9145 19.5446 60.5099 19.9641 60.2102 20.4736C59.9405 20.983 59.8057 21.5224 59.8057 22.0918V25.2383C59.8057 25.8077 59.9405 26.3621 60.2102 26.9015C60.5099 27.4409 60.8995 27.8754 61.3789 28.2051C61.8884 28.5347 62.4428 28.6995 63.0421 28.6995Z"
              fill={clsx(withGradient && 'url(#paint3_linear_473_1486)')}
            />
            <path
              d="M85.7962 34.7678C83.9383 34.7678 82.2301 34.3333 80.6719 33.4643C79.1136 32.5653 77.8699 31.3965 76.941 29.9581C76.012 28.5197 75.5475 26.9764 75.5475 25.3282V15.0795C75.5475 14.5401 75.8921 14.0456 76.5814 13.5961C77.2706 13.1466 78.0947 12.9219 79.0536 12.9219C79.9826 12.9219 80.7917 13.1466 81.481 13.5961C82.1702 14.0456 82.5148 14.5401 82.5148 15.0795V25.3282C82.5148 25.8976 82.6647 26.437 82.9643 26.9464C83.264 27.4559 83.6536 27.8754 84.1331 28.2051C84.6425 28.5347 85.1969 28.6995 85.7962 28.6995C86.3956 28.6995 86.935 28.5347 87.4144 28.2051C87.9239 27.8754 88.3135 27.4559 88.5832 26.9464C88.8828 26.437 89.0327 25.8976 89.0327 25.3282V15.0345C89.0327 14.4652 89.3773 13.9707 90.0665 13.5512C90.7857 13.1316 91.6098 12.9219 92.5388 12.9219C93.5577 12.9219 94.3818 13.1316 95.0111 13.5512C95.6704 13.9707 96 14.4652 96 15.0345V25.3282C96 27.0064 95.5355 28.5647 94.6065 30.0031C93.6776 31.4415 92.4339 32.5952 90.8756 33.4643C89.3473 34.3333 87.6542 34.7678 85.7962 34.7678Z"
              fill={clsx(withGradient && 'url(#paint4_linear_473_1486)')}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.7129 1.14054C31.3486 1.14054 29.4319 3.05721 29.4319 5.42154C29.4319 7.78588 31.3486 9.70255 33.7129 9.70255H44.9506C47.3149 9.70255 49.2316 7.78588 49.2316 5.42154C49.2316 3.05721 47.3149 1.14054 44.9506 1.14054H33.7129ZM33.7129 8.6323C35.4862 8.6323 36.9237 7.19479 36.9237 5.42154C36.9237 3.64829 35.4862 2.21079 33.7129 2.21079C31.9397 2.21079 30.5022 3.64829 30.5022 5.42154C30.5022 7.19479 31.9397 8.6323 33.7129 8.6323Z"
              fill={clsx(withGradient && 'url(#paint5_linear_473_1486)')}
            />
            <defs>
              <linearGradient
                id="paint0_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_473_1486"
                x1="-4.08717e-08"
                y1="21.7643"
                x2="48.374"
                y2="69.7628"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6A03BB" />
                <stop offset="1" stopColor="#1CBCC7" />
              </linearGradient>
            </defs>
          </svg>
        </ConditionalWrapper>
      </ConditionalWrapper>
    </div>
  );
};

export default Logo;
