import { create } from 'zustand';

const initPopupState: PopupState = {
  isEventImageSelectorOpen: false,
  isTimeLengthSlotSelectorOpen: false,
  isEventSummaryShown: false,
  isSubmitEventShown: false,
  isEventTimeSlotsModalOpen: false,
  isUserImageSelectorOpen: false,
  isShareBarShown: true,
  isShareEventShown: false,
  isTimeslotVotedUserListOpen: false,
  isEventVotedUserListOpen: false,
  isEventWontGoUserListOpen: false,
  isLeavePromptModalOpen: false,
  isCreatedEventModalOpen: false,
  isSetNameEventModalOpen: false,
} as const;

interface PopupState {
  isEventImageSelectorOpen: boolean;
  isTimeLengthSlotSelectorOpen: boolean;
  isEventSummaryShown: boolean;
  isSubmitEventShown: boolean;
  isEventTimeSlotsModalOpen: boolean;
  isUserImageSelectorOpen: boolean;
  isShareBarShown: boolean;
  isShareEventShown: boolean;
  isTimeslotVotedUserListOpen: boolean | string;
  isEventVotedUserListOpen: boolean;
  isEventWontGoUserListOpen: boolean;
  isLeavePromptModalOpen: boolean;
  isCreatedEventModalOpen: boolean;
  isSetNameEventModalOpen: boolean;
}

interface PopupStoreProps {
  popupState: PopupState;
  setPopupState: (popupState: PopupState | Partial<PopupState>) => void;
}

const usePopupStore = create<PopupStoreProps>((set) => ({
  popupState: initPopupState,
  setPopupState: (popupState) => {
    set(() => ({
      popupState: {
        ...initPopupState,
        ...popupState,
        ...(Object.values(popupState).some((value) => value) && {
          isShareBarShown: false,
        }),
      },
    }));
  },
}));

export default usePopupStore;
