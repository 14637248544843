import { useCallback } from 'react';

import { GaEventData } from '@analytics/types/Ga';

import useGa from './useGa';

const useGaPage = () => {
  const logToGa = useGa();

  return useCallback(
    (pageType: NonNullable<GaEventData['page']>['type']) => {
      logToGa({
        page: { type: pageType },
      });
    },
    [logToGa],
  );
};

export default useGaPage;
