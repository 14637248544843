import { ReactNode } from 'react';

import clsx from 'clsx';

export interface FooterLinkProps {
  href?: string | undefined;
  target?: string;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}

const FooterLink = ({
  href,
  target,
  className,
  onClick,
  children,
}: FooterLinkProps) => (
  <a
    href={href}
    onClick={onClick}
    target={target}
    className={clsx(
      className,
      'font-montserrat text-[14px]/[28px] text-brand-primary-100 no-underline underline-offset-1 hover:underline',
    )}
  >
    {children}
  </a>
);

export default FooterLink;
