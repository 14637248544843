import { useEffect } from 'react';

import { getDevice } from '@device/getDevice';

import { sasicQueuePush } from '../Sasic';
import { SasicAction, SasicAttributes } from '../SasicTypes';

const useSasicAttributes = ({
  keyword,
}: Pick<SasicAttributes, 'keyword'>): void => {
  useEffect(() => {
    const sasicOptions: SasicAttributes = {
      site: 'hp',
      area: 'pujdu',
      targets: {
        template: undefined,
        id: undefined,
        sec1: 'pujdu',
        sec2: undefined,
        device: getDevice(),
      },
      keyword: ['pujdu', ...keyword],
    };

    sasicQueuePush([SasicAction.PAGE, { options: sasicOptions }]);
  }, [keyword]);
};

export default useSasicAttributes;
