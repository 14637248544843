import { MouseEventHandler, ReactElement, ReactNode } from 'react';

import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useTutorialStore } from '@event/store/useTutorialStore';
import ConditionalWrapper from '@ui/components/ConditionalWrapper';
import {
  isNotTypeClose,
  isSizeBig,
  isSizeBigNotDisabled,
  isSizeRegular,
  isSizeRegularActive,
  isSizeRegularNotDisabled,
  isSizeRegularNotDisabledNotTypeVote,
  isSizeRegularNotDisabledTypeButton,
  isSizeRegularNotDisabledTypePill,
  isSizeRegularNotTypeVote,
  isSizeRegularNotTypeVoteChildren,
  isSizeRegularNotTypeVoteNotChildren,
  isSizeRegularTypeButtonOrClose,
  isSizeRegularTypePillOrVote,
  isSizeRegularTypeVote,
  isSizeSmall,
  isSizeSmaller,
  isSizeSmallOrSmaller,
  isSizeSmallOrSmallerActive,
  isSizeSmallOrSmallerNotDisabled,
  isTypeVote,
} from '@ui/helpers/ButtonHelpers';
import useClientStore from '@utils/hooks/useClientStore';
import useTouchSupport from '@utils/hooks/useTouchSupport';

export interface ButtonProps {
  type?: 'button' | 'pill' | 'close' | 'vote';
  addonBefore?: ReactElement;
  addonAfter?: ReactElement;
  children?: ReactNode;
  className?: string;
  classNameWrapper?: string;
  size?: 'smaller' | 'small' | 'regular' | 'big';
  title?: string;
  disabled?: boolean;
  link?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onTouch?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
}
const Button = ({
  type = 'button',
  addonAfter,
  addonBefore,
  children,
  className,
  classNameWrapper,
  size = 'regular',
  title,
  disabled = false,
  link,
  onClick,
  onTouch,
  isActive = false,
}: ButtonProps) => {
  const tutorialStore = useClientStore(useTutorialStore, (state) => state);
  const { pathname } = useRouter();
  const isTouchSupport = useTouchSupport();

  const isTutorialEventType =
    tutorialStore?.tutorialStep === 'eventType' &&
    tutorialStore?.isTutorialActive;

  const isVotePage = pathname === '/e/[slug]';

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onTouch && isTouchSupport) {
      onTouch(event);
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <ConditionalWrapper
      condition={!!link}
      wrapper={(wrapperChildren) => <Link href={link!}>{wrapperChildren}</Link>}
    >
      <div
        className={clsx(
          'group/button rounded-[32px] transition-all duration-300 before:absolute before:inset-0 before:rounded-[32px] before:transition-all before:duration-300 motion-reduce:transition-none before:motion-reduce:transition-none',
          isSizeRegular(size) && 'before:m-[-2px] before:bg-size-300%',
          isSizeRegularNotTypeVote(size, type) &&
            'before:bg-brand-transparent-100',
          isSizeRegularNotDisabledNotTypeVote(size, disabled, type) &&
            'hover:shadow-button-regular hover:before:bg-button-regular active:before:bg-button-regular active:before:bg-right',
          isSizeRegularActive(size, isActive) &&
            'before:bg-button-regular before:bg-right',
          isSizeSmallOrSmaller(size) &&
            'before:m-[-2px] before:bg-brand-primary-purple',
          isSizeSmallOrSmallerNotDisabled(size, disabled) &&
            'hover:before:bg-brand-white-100 group-hover/notificationSemi:before:bg-brand-white-100 group-hover/timeTableItem:before:bg-brand-white-100',
          isSizeSmallOrSmallerActive(size, isActive) &&
            'before:bg-brand-white-100',
          isSizeRegularNotDisabledTypeButton(size, disabled, type) &&
            'before:bg-brand-transparent-100',
          isSizeRegularNotDisabledTypePill(size, disabled, type) &&
            'before:bg-brand-gray-300',
          isNotTypeClose(type) && 'relative',
          isTypeVote(type) &&
            'before:bg-brand-primary-100 hover:before:bg-brand-primary-100',
          isSizeBigNotDisabled(size, disabled) &&
            'before:bg-brand-transparent-100',
          isTutorialEventType && !isVotePage && 'z-20',
          classNameWrapper,
        )}
      >
        <button
          onClick={(event) => handleClick(event)}
          type="button"
          className={clsx(
            'relative inline-flex cursor-pointer items-center justify-center rounded-[32px] text-center font-montserrat no-underline transition-all duration-300 disabled:cursor-not-allowed motion-reduce:transition-none',
            isSizeRegular(size) &&
              'min-h-[42px] text-[14px]/[16px] font-medium',
            isSizeRegularNotTypeVote(size, type) && 'text-brand-gray-100',
            isSizeRegularTypeVote(size, type) && 'text-brand-primary-100',
            isSizeRegularNotDisabled(size, disabled) &&
              'hover:bg-brand-white-100 hover:text-brand-primary-100 active:bg-brand-white-100 active:text-brand-primary-100 group-hover/button:bg-brand-white-100 group-hover/button:text-brand-primary-100 group-active/button:bg-brand-white-100 group-active/button:text-brand-primary-100',
            isSizeRegularActive(size, isActive) &&
              'bg-brand-white-100 text-brand-primary-100',
            isSizeSmallOrSmaller(size) &&
              'bg-brand-primary-purple py-3 text-[14px]/[16px] font-medium text-brand-white-100',
            isSizeSmall(size) && 'w-[40px]',
            isSizeSmaller(size) && 'h-[32px] w-[32px]',
            isSizeSmallOrSmallerNotDisabled(size, disabled) &&
              'hover:bg-brand-white-100 hover:text-brand-primary-purple active:bg-brand-white-100 active:text-brand-primary-purple group-hover/button:bg-brand-white-100 group-hover/notificationSemi:bg-brand-white-100 group-hover/timeTableItem:bg-brand-white-100 group-hover/button:text-brand-primary-purple group-hover/notificationSemi:text-brand-primary-purple group-hover/timeTableItem:text-brand-primary-purple group-active/button:bg-brand-white-100 group-active/notificationSemi:bg-brand-white-100 group-active/timeTableItem:bg-brand-white-100 group-active/button:text-brand-primary-purple group-active/notificationSemi:text-brand-primary-purple group-active/timeTableItem:text-brand-primary-purple',
            isSizeSmallOrSmallerActive(size, isActive) &&
              'bg-brand-white-100 text-brand-primary-purple',
            isSizeRegularNotTypeVoteChildren(size, type, children) &&
              'px-6 py-3',
            isSizeRegularNotTypeVoteNotChildren(size, type, children) &&
              'min-h-[40px] min-w-[40px] p-[11px]',
            isSizeRegularTypePillOrVote(size, type) && 'bg-brand-white-100',
            isSizeRegularTypeButtonOrClose(size, type) && 'bg-brand-gray-500',
            isSizeRegularTypeVote(size, type) &&
              'min-h-[44px] min-w-[44px] p-3',
            isSizeBig(size) &&
              'min-w-[66px] whitespace-nowrap bg-button-big bg-size-600% p-6 text-[16px]/[16px] font-semibold text-brand-white-100 shadow-button-big md:px-12',
            isSizeBigNotDisabled(size, disabled) &&
              'hover:bg-center hover:shadow-button-big-hover active:bg-right active:shadow-none group-hover/button:shadow-button-big-hover group-active/button:bg-right group-active/button:shadow-none',
            className,
          )}
          title={title}
          disabled={disabled}
        >
          {addonBefore}
          {children}
          {addonAfter}
        </button>
      </div>
    </ConditionalWrapper>
  );
};

export default Button;
