import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UseTutorialState } from '@event/types';

const getNextTutorialStep = (
  tutorialStep: UseTutorialState['tutorialStep'],
): UseTutorialState['tutorialStep'] | null => {
  switch (tutorialStep) {
    case 'eventType':
      return 'eventTitle';
    case 'eventTitle':
      return 'eventDescription';
    case 'eventDescription':
      return 'eventLocation';
    case 'eventLocation':
      return 'eventTimeSlots';
    case 'eventTimeSlots':
      return null;
    default:
      return null;
  }
};

export const useTutorialStore = create(
  persist<UseTutorialState>(
    (set, get) => ({
      isTutorialActive: true,
      setIsTutorialActive: (isTutorialActive) => {
        set(() => ({ isTutorialActive }));
      },
      tutorialStep: 'eventType',
      setTutorialStep: (tutorialStep, isActive = true) => {
        set(() => ({
          tutorialStep,
          isTutorialActive:
            isActive || getNextTutorialStep(tutorialStep) !== null,
        }));
      },
      goToNextStep: () => {
        const nextStep = getNextTutorialStep(get().tutorialStep);
        if (nextStep) {
          set(() => ({
            tutorialStep: nextStep,
          }));
        }
      },
    }),
    {
      name: 'isTutorialActive',
    },
  ),
);
