export const getCdnUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return '';
    case 'production':
      return process.env.NEXT_PUBLIC_STATIC_ASSETS_URL;
    default:
      return '';
  }
};

export const getAssetUrl = (path: string) => {
  const assetPathUrl = path.replace(/^\//, '');

  return `${getCdnUrl()}/${assetPathUrl}`;
};
