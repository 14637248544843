import { create } from 'zustand';

import { TimetabesState } from '@event/types';

const useTimetablesStore = create<TimetabesState>((set, get) => ({
  timetables: get()?.timetables || [],
  setTimetables: (newTimetables) => set({ timetables: newTimetables }),
  removeTimetable: (uuid) =>
    set((state) => {
      return {
        timetables: state.timetables.filter(
          (timetable) => timetable.uuid !== uuid,
        ),
      };
    }),
  addTimetable: (timetable) =>
    set((state) => ({ timetables: [...state.timetables, timetable] })),
}));

export default useTimetablesStore;
