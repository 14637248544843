import { Device } from '@device/Device';

export type SasicActionType = [SasicAction, ...unknown[]];

export enum SasicAction {
  POSITION = 'position',
  LOAD_POSITION = 'loadPosition',
  REMOVE_POSITION = 'removePosition',
  LOAD_ALL = 'loadAll',
  PAGE = 'page',
}

export interface SasicAttributes {
  site: 'hp';
  area: 'pujdu';
  targets: {
    template?: 'pudu';
    id?: string;
    sec1?: 'pujdu';
    sec2?: string;
    device?: Device;
  };
  keyword: string[];
}
