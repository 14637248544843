import { SasicAction, SasicActionType } from './SasicTypes';

export const sasicQueuePush = (...payload: SasicActionType[]) => {
  window._sasic_queue = window._sasic_queue || [];
  window._sasic_queue.push(...payload);
};

export const reloadHeaderBidding = () => {
  const { cpexPackage } = window;

  if (cpexPackage) {
    try {
      cpexPackage.refresh();
    } catch (error) {
      window._als_queue.push({
        event: 'ALS_page_error',
        data: { message: `Failed to refresh CPEX package. ${error}` },
      });
    }
  }
};

export const reloadAllAdverts = () => {
  reloadHeaderBidding();
  sasicQueuePush([SasicAction.LOAD_ALL, { reenableHb: true }]);
};
