import { ReactNode } from 'react';

export const isSizeRegular = (size: 'smaller' | 'small' | 'regular' | 'big') =>
  size === 'regular';

export const isSizeSmall = (size: 'smaller' | 'small' | 'regular' | 'big') =>
  size === 'small';

export const isSizeSmaller = (size: 'smaller' | 'small' | 'regular' | 'big') =>
  size === 'smaller';

export const isSizeBig = (size: 'smaller' | 'small' | 'regular' | 'big') =>
  size === 'big';

export const isSizeSmallOrSmaller = (
  size: 'smaller' | 'small' | 'regular' | 'big',
) => ['small', 'smaller'].includes(size);

export const isTypeVote = (type: 'button' | 'pill' | 'close' | 'vote') =>
  type === 'vote';

export const isSizeRegularActive = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  isActive: boolean,
) => size === 'regular' && isActive;

export const isSizeRegularNotDisabled = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
) => size === 'regular' && !disabled;

export const isSizeRegularTypeVote = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && type === 'vote';

export const isSizeRegularNotTypeVote = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && type !== 'vote';

export const isSizeBigNotDisabled = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
) => size === 'big' && !disabled;

export const isSizeRegularNotDisabledNotTypeVote = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && type !== 'vote' && !disabled;

export const isSizeRegularNotDisabledTypeButton = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && type === 'button' && !disabled;

export const isSizeRegularNotDisabledTypePill = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && type === 'pill' && !disabled;

export const isNotTypeClose = (type: 'button' | 'pill' | 'close' | 'vote') =>
  type !== 'close';

export const isSizeSmallOrSmallerNotDisabled = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  disabled: boolean,
) => ['small', 'smaller'].includes(size) && !disabled;

export const isSizeSmallOrSmallerActive = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  isActive: boolean,
) => ['small', 'smaller'].includes(size) && isActive;

export const isSizeRegularNotTypeVoteChildren = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
  children: ReactNode,
) => size === 'regular' && type !== 'vote' && children;

export const isSizeRegularNotTypeVoteNotChildren = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
  children: ReactNode,
) => size === 'regular' && type !== 'vote' && !children;

export const isSizeRegularTypePillOrVote = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && ['pill', 'vote'].includes(type);

export const isSizeRegularTypeButtonOrClose = (
  size: 'smaller' | 'small' | 'regular' | 'big',
  type: 'button' | 'pill' | 'close' | 'vote',
) => size === 'regular' && ['button', 'close'].includes(type);
