import clsx from 'clsx';

import FooterLink from '@footer/components/FooterLink';
import { FOOTER_BOTTOM_LINKS } from '@footer/constants/footerLinks';
import LogoCentrum from '@ui/components/LogoCentrum';

export interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => (
  <footer
    className={clsx(
      'relative mb-24 flex w-full flex-wrap justify-center',
      className,
    )}
  >
    <div className="flex w-full flex-wrap justify-center md:space-x-8">
      {FOOTER_BOTTOM_LINKS.map(({ title, location, linkProps }) => (
        <FooterLink
          key={title}
          href={location}
          {...linkProps}
          className="w-full text-center md:w-auto"
        >
          {title}
        </FooterLink>
      ))}
    </div>
    <div className="flex w-full flex-wrap justify-center md:space-x-8">
      <span className="my-4 w-full justify-center text-center font-montserrat text-[14px]/[28px] text-brand-black-100 no-underline md:mb-0 md:w-auto">
        Copyright {new Date().getFullYear()}, Economia, a.s.
      </span>
      <div className="flex w-full flex-wrap items-start justify-center md:mt-4 md:w-auto">
        <span className="mb-2 mr-4 flex items-start font-montserrat text-[14px]/[28px] text-brand-black-100 no-underline">
          Přináší vám:
        </span>
        <LogoCentrum />
      </div>
    </div>
  </footer>
);

export default Footer;
