export const FOOTER_BOTTOM_LINKS = [
  {
    title: 'Ochrana osobních údajů',
    location: 'https://economia.cz/ochrana-osobnich-udaju/',
    linkProps: {
      target: '_blank',
    },
  },
  {
    title: 'Prohlášení o cookies',
    location: 'https://economia.cz/prohlaseni-o-cookies/',
    linkProps: {
      target: '_blank',
    },
  },
  {
    title: 'Nastavení soukromí',
    location: '#',
    linkProps: {
      className: 'eco-cmp-show-settings cursor-pointer',
      onClick: () => window?.Didomi?.preferences?.show(),
    },
  },
] as const;
