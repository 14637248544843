import { useState, useEffect, useMemo } from 'react';

const useTouchSupport = () => {
  const [isTouchSupported, setIsTouchSupported] = useState(false);

  const handleTouchSupport = useMemo(
    () => () => {
      setIsTouchSupported(
        ('ontouchstart' in window || navigator.maxTouchPoints > 0) &&
          document.documentElement.clientWidth < 768,
      );
    },
    [],
  );

  useEffect(() => {
    handleTouchSupport();

    document.addEventListener('touchstart', handleTouchSupport);

    return () => {
      document.removeEventListener('touchstart', handleTouchSupport);
    };
  }, [handleTouchSupport]);

  return isTouchSupported;
};

export default useTouchSupport;
