import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import CustomHead from './CustomHead';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  favicon?: string;
  isIconsWithResolutions?: boolean;
}

const Meta = ({
  title,
  description,
  image,
  noIndex,
  noFollow,
  favicon = 'favicon.ico',
  isIconsWithResolutions = true,
}: MetaProps) => {
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  const { asPath } = useRouter();
  const url = `${origin}${asPath === '/' ? '' : asPath}`.split('?')[0];
  const imageUrl = image || `/android-chrome-512x512.png`;

  return (
    <>
      <CustomHead
        favicon={favicon}
        isIconsWithResolutions={isIconsWithResolutions}
      />
      <NextSeo
        additionalMetaTags={[
          {
            name: 'referrer',
            content: 'no-referrer-when-downgrade',
          },
          {
            name: 'google-site-verification',
            content: 'dclHIxFDExa4BqyEQ5fEu2TOayUaEzAenWxZSRe3GKs',
          },
        ]}
        title={title}
        description={description}
        canonical={url}
        noindex={noIndex}
        nofollow={noFollow}
        openGraph={{
          title,
          description,
          url,
          siteName: 'Centrum Půjdu',
          locale: 'cs_CZ',
          images: [{ url: imageUrl, width: 512, height: 512 }],
        }}
        twitter={{
          cardType: 'summary_large_image',
          site: url,
        }}
      />
    </>
  );
};

export default Meta;
