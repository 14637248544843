import { Device } from './Device';

const DEVICE_BREAKPOINT = 1024 as const;

export const getDevice = () => {
  if (document.documentElement.clientWidth > DEVICE_BREAKPOINT) {
    return Device.DESKTOP;
  }

  return Device.MOBILE;
};
