const LogoCentrum = () => (
  <div className="relative top-[-8px] flex">
    <a href="https://www.centrum.cz/" target="_blank" className="flex">
      <svg
        width="185"
        height="31"
        viewBox="0 0 185 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.103 30H141.88C141.418 30 141 29.727 141 29.3926L141.015 26.5746C141.019 26.2707 141.391 26 141.898 26H144.122C144.585 26 145.001 26.2398 145 26.5746L144.984 29.3926C144.981 29.727 144.611 30 144.103 30Z"
          fill="#F6941C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.089 29.6483C158.709 29.8379 156.828 30 154.862 30C149.18 30 145.976 26.9341 146 22.2439L146.011 19.7551C146.03 15.0625 149.22 12 154.947 12C156.87 12 158.789 12.1593 160.17 12.3496C160.835 12.4481 161.002 12.6064 161 13.051L160.995 14.2014C160.992 14.5204 160.658 14.6798 160.284 14.6798H160.114C158.277 14.5204 156.983 14.4564 154.935 14.4564C151.593 14.4564 149.702 16.1479 149.686 19.7551L149.676 22.2439C149.658 25.8167 151.489 27.5408 154.874 27.5408C156.921 27.5408 158.217 27.4761 160.056 27.3175H160.224C160.6 27.3175 160.932 27.4761 160.931 27.7968L160.925 28.9473C160.925 29.3592 160.755 29.5492 160.089 29.6483Z"
          fill="#F6941C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178.188 30H166.377C164.695 30 163.996 29.1153 164 28.2002L164.004 27.5802C164.005 26.6612 164.241 26.171 164.91 25.4508L175.045 14.9464C175.125 14.8472 175.203 14.7473 175.203 14.6513C175.203 14.5197 175.125 14.422 174.813 14.422H165.309C164.879 14.422 164.53 14.1276 164.529 13.767L164.536 12.6536C164.535 12.2936 164.89 12 165.321 12H176.817C178.422 12 179.005 12.6243 179 13.6025L178.998 14.3891C178.994 15.0773 178.717 15.5675 178.09 16.1879L167.76 26.9552C167.602 27.0861 167.563 27.217 167.563 27.3158C167.561 27.4789 167.6 27.5802 167.873 27.5802H178.199C178.629 27.5802 178.98 27.872 178.977 28.232L178.974 29.3461C178.973 29.706 178.617 30 178.188 30Z"
          fill="#F6941C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1858 29.5426C13.1007 29.7549 11.3554 30 9.03071 30C3.56643 30 0.00140868 27.254 0 22.2828L0.00246519 19.7213C0.00211302 14.7436 3.56643 12 9.03106 12C11.3568 12 13.1007 12.2438 14.1865 12.457C14.7676 12.5775 14.9982 12.7032 14.9989 13.1588L15 15.8747C14.9996 16.1814 14.6517 16.3937 14.2629 16.3937H14.1854C12.751 16.2708 11.6656 16.1814 9.45824 16.1814C7.71289 16.1814 6.27955 17.0048 6.28061 19.7213L6.2792 22.2828C6.28026 24.9993 7.71253 25.8203 9.45754 25.8203C11.6656 25.8203 12.7514 25.7299 14.1851 25.6077H14.2619C14.6496 25.6077 14.9989 25.8203 15 26.1263L14.9986 28.8425C14.9982 29.3009 14.7679 29.4218 14.1858 29.5426Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9997 19.8573C29.9986 17.2868 28.6921 16 26.5383 16C24.4215 16 23.0003 17.3238 23 19.8573L23.0003 20H30L29.9997 19.8573ZM35.202 22.5265H23.0813L23.0816 22.5864C23.0809 24.3252 23.9915 25.9141 26.9941 25.9141C29.388 25.9141 32.7698 25.7628 34.2901 25.6371H34.4041C34.8222 25.6371 35.1254 25.7919 35.1264 26.1263L35.1126 28.0815C35.1129 28.5704 34.961 28.784 34.3139 28.9069C31.8841 29.3934 29.8431 30 26.538 30C21.9404 30 17.0021 28.0469 17 22.1585V20.0236C17.0021 15.1123 20.571 12 26.6146 12C32.8465 12 36.0007 15.4163 35.9997 20.0236L36 21.7936C35.9997 22.2509 35.6941 22.5265 35.202 22.5265Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.2317 30H51.8039C51.4019 30 51.0738 29.6892 51.0725 29.3479V19.032C51.0718 16.864 50.5964 16.15 48.3647 16.15C47.3035 16.15 46.1686 16.5828 44.9279 17.1425L44.9245 29.3479C44.9279 29.6892 44.5977 30 44.1951 30H39.768C39.3647 30 38.9983 29.6892 39.0007 29.3479L39 12.8676C38.999 12.5277 39.3281 12.2475 39.7304 12.2475H43.8275C44.2301 12.2475 44.5598 12.5277 44.5598 12.8676L44.5595 13.6111C46.5728 12.4036 48.1822 12 50.5233 12C55.8281 12 57 15.1291 57 19.032L56.9987 29.3479C57.0007 29.6892 56.633 30 56.2317 30Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.893 27.5406C71.2732 27.5406 67.8858 25.5787 69.2372 20.5372L72.3251 8.64448L77.1124 9.17334L77.1957 9.02059L69.6849 0L59 8.97934L59.0417 9.18103L64.1275 8.65567L61.1204 20.2698C59.6383 26.1152 63.0609 30 69.873 30H77.3894L78 27.5406H73.893Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.4277 16.15H85.1211C83.249 16.15 81.6061 16.6162 80.1931 17.2349L80.1913 29.3479C80.1924 29.6892 79.8496 30 79.4284 30H74.8032C74.3851 30 74.0017 29.6892 74 29.3479L74.0007 12.8676C74 12.5277 74.3459 12.2471 74.7647 12.2471H79.3128C79.7336 12.2471 80.0767 12.5277 80.0795 12.8676L80.0778 13.4867C81.4911 12.5277 83.4025 12 85.2367 12C85.6576 12 86.0007 12.2805 86 12.6194V15.5636C85.999 15.8435 85.7712 16.15 85.4277 16.15Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.269 29.7535H101.171C100.768 29.7535 100.44 29.4747 100.439 29.1341L100.438 28.3884C98.4259 29.5981 96.8198 30 94.4744 30C89.1729 30 88.002 26.8724 88.001 22.9672L88 12.6511C87.9993 12.3108 88.3664 12 88.7673 12H93.1948C93.5977 12 93.9279 12.3108 93.9262 12.6511L93.9289 22.9672C93.9295 25.1357 94.4046 25.8501 96.6356 25.8501C97.6975 25.8501 98.8311 25.4162 100.074 24.8569L100.072 12.6511C100.072 12.3108 100.403 12 100.805 12H105.231C105.636 12 105.998 12.3108 106 12.6511V29.1341C106 29.4747 105.671 29.7535 105.269 29.7535Z"
          fill="#00467F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.193 30H133.545C133.122 30 132.777 29.6892 132.777 29.3479V18.7832C132.777 16.8004 132.202 16.213 130.435 16.213C129.78 16.213 128.629 16.3364 127.476 16.7709C127.55 17.3889 127.592 18.1029 127.592 18.7832V29.3479C127.592 29.6892 127.246 30 126.823 30H122.176C121.753 30 121.369 29.6892 121.369 29.3479L121.37 18.7832C121.369 16.8004 120.909 16.213 119.141 16.213C118.412 16.213 117.413 16.5244 116.222 17.018L116.224 29.3479C116.222 29.6892 115.878 30 115.455 30H110.807C110.384 30 110.001 29.6892 110.001 29.3479L110 12.8676C110.001 12.5277 110.345 12.2475 110.77 12.2475H115.149C115.569 12.2475 115.913 12.5277 115.918 12.8676L115.914 13.5496C117.528 12.6837 119.371 12 121.6 12C123.636 12 125.056 12.4641 126.13 13.518C128.361 12.4957 129.896 12 132.393 12C137.463 12 138.999 14.7256 139 18.7832L138.998 29.3479C139 29.6892 138.614 30 138.193 30Z"
          fill="#00467F"
        />
      </svg>
    </a>
  </div>
);

export default LogoCentrum;
